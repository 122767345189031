import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  
  private menuData       = [];
  private permissoes:any = null;
  public openMenuEmitter = new EventEmitter(); 
  constructor() { }

  /**
   * 
   * f
   * 
   */
  getMenuHtml(permissoes=null){
    
    this.permissoes = permissoes; 
    let html        = "";
    this.menuData   = this.getMenu();
    let isPermited  = true;

    this.menuData.forEach(item => {

      if(item.item_pai == 0){

        isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

        if(isPermited){
          if(this.hasItens(item.id)){
            html += this.readItensParent(item);
          }else{
            html += this.itemHtml(item);
          }
        }  

      }
      
    });
  
    return html;

  } 
  /**
   * 
   * Verifica se o item tem sub-itens
   * 
   * @param id 
   * @param menuData 
   * 
   */
  hasItens(id){

    let status = false;
    let item   = null;

    for (var index = 0; index < this.menuData.length; index++) {
      
      item = this.menuData[index];

      if(id == item.item_pai){

        status = true;
        break;

      }
      
    }

    return status;

  }
  /**
   * 
   * Busca os SUB-ITENS
   * 
   * @param item 
   * 
   */
  readItensParent(item){
    
    let isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

    var html    = "";

    if(isPermited){
    
      let itens   = this.getItensParent(item.id);
      let itemSub = null;

      html += "<li class='sub-menu'>";
      html += "<a class='a-sub-itens' data-sub-itens>"; 
      html += item.titulo;
      html += "<i class='material-icons seta'>arrow_right</i>";
      html += "</a>";
      html += "<ul>";
      for (var index = 0; index < itens.length; index++) {
        
        item = itens[index];
        isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

        if(isPermited){
          if(this.hasItens(item.id)){
            html += this.readItensParent(item);        
          }else{
            html += this.itemHtml(item,"item-of-sub-menu");
          }
        }  

        
      }
      html += "</ul>";
      html += "</li>"; 

    }  
    return html;

  }
  /**
   * 
   * Retorna os sub itens de um item
   * 
   * @param id 
   * @param menuData
   *  
   */
  getItensParent(id,menu=null){

    let data     = [];
    let item     = null;
    let menuData = menu == null ? this.menuData : menu;

    for(var index = 0; index < menuData.length; index++) {
      
      item = this.menuData[index];

      if(id == item.item_pai){
        data.push(item);
      }
      
    }

    return data;

  }
  itemHtml(item,classCustom=""){

    let html  = ""; 
    html += "<li class='"+classCustom+"'>";
    html += "<a data-route='"+item.link+"'>";
    html += item.titulo;
    html += "</a>";
    html += "</li>";

    return html;

  }
  /**
   * 
   * Retorna o item da permissão baseado no apelido
   * 
   */
  isPermited(apelido){

    let status = true;

    for(var i = 0; i< this.permissoes.length;i++) {
      
      if(apelido == this.permissoes[i].apelido){
        status = this.permissoes[i].view;
        break;
      }
      
    }


    return status;


  }
  /**
   * 
   * Retorna os itens de um menu
   * 
   * 
   */
  getMenu(){
    
    let menu = [];
    
    /*menu.push({id:1,apelido:"home",link:Api.ADM_BASE,icon:null,titulo:"Home",title:"Home",item_pai:0,ordem:0});
    menu.push({id:2,apelido:"configuracao",link:Api.ADM_BASE+"/configuracao",icon:null,titulo:"Configuração",title:"Configuração",item_pai:0,ordem:1});
    menu.push({id:3,apelido:"gerenciador-de-usuarios",link:Api.ADM_BASE+"/gerenciador-de-usuarios",icon:null,titulo:"Gerenciador de Usuário",title:"Ger. de Usuários",item_pai:0,ordem:2});
    menu.push({id:4,apelido:"gerenciador-de-usuarios.usuarios",link:Api.ADM_BASE+"/gerenciador-de-usuarios/usuarios",icon:null,titulo:"Usuários",title:"Usuários",item_pai:3,ordem:1});
    menu.push({id:5,apelido:"gerenciador-de-usuarios.grupos",link:Api.ADM_BASE+"/gerenciador-de-usuarios/grupos",icon:null,titulo:"Grupos",title:"Grupos",item_pai:3,ordem:2});
    menu.push({id:7,apelido:"logs",link:Api.ADM_BASE+"/logs",icon:null,titulo:"Logs",title:"Logs",item_pai:0,ordem:20});
    menu.push({id:8,apelido:"conteudo",link:Api.ADM_BASE+"/conteudo",icon:null,titulo:"Páginas",title:"Páginas",item_pai:0,ordem:3});
    
    menu.push({id:9,apelido:"cadastro",link:Api.ADM_BASE+"/cadastro",icon:null,titulo:"Cadastro",title:"Cadastro",item_pai:0,ordem:3});
    menu.push({id:19,apelido:"cadastro.produto",link:Api.ADM_BASE+"/cadastro/produto",icon:null,titulo:"Produtos",title:"Produtos",item_pai:9,ordem:1});
    menu.push({id:24,apelido:"componentes.produto.produto",link:Api.ADM_BASE+"/cadastro/produto/produto",icon:null,titulo:"Produto",title:"Produto",item_pai:19,ordem:0});
    menu.push({id:21,apelido:"cadastro.produto.categoria",link:Api.ADM_BASE+"/cadastro/produto/categoria",icon:null,titulo:"Categoria",title:"Categoria",item_pai:19,ordem:1});
    menu.push({id:53,apelido:"cadastro.produto.macro_regiao",link:Api.ADM_BASE+"/cadastro/produto/macro-regiao",icon:null,titulo:"Macro Região",title:"Macro Região",item_pai:19,ordem:3});
    menu.push({id:54,apelido:"cadastro.produto.aeroporto",link:Api.ADM_BASE+"/cadastro/produto/aeroportos",icon:null,titulo:"Aeroporto",title:"Aeroporto",item_pai:19,ordem:4});
    menu.push({id:55,apelido:"cadastro.produto.cia_aerea",link:Api.ADM_BASE+"/cadastro/produto/cias-aereas",icon:null,titulo:"Cias Aéreas",title:"Cias Aéreas",item_pai:19,ordem:5});
    menu.push({id:76,apelido:"cadastro.produto.cidade",link:Api.ADM_BASE+"/cadastro/produto/cidade",icon:null,titulo:"Cidade / Destino",title:"Cidade",item_pai:19,ordem:5});
    menu.push({id:77,apelido:"cadastro.produto.classificacao",link:Api.ADM_BASE+"/cadastro/produto/classificacao",icon:null,titulo:"Classificação",title:"Classificação",item_pai:19,ordem:6});
    menu.push({id:78,apelido:"cadastro.produto.caracteristica",link:Api.ADM_BASE+"/cadastro/produto/caracteristica",icon:null,titulo:"Características",title:"Características",item_pai:19,ordem:7});
    menu.push({id:103,apelido:"cadastro.produto.beneficio",link:Api.ADM_BASE+"/cadastro/produto/beneficio",icon:null,titulo:"Benefícios",title:"Benefícios",item_pai:19,ordem:10});
    
    menu.push({id:126,apelido:"cadastro.duvidas_frequentes",link:Api.ADM_BASE+"/cadastro/duvidas-frequentes",icon:null,titulo:"Dúvidas Frequentes",title:"Dúvidas Frequentes",item_pai:9,ordem:2});
    
    menu.push({id:10,apelido:"modulos",link:Api.ADM_BASE+"/modulos",icon:null,titulo:"Módulos",title:"Módulos",item_pai:0,ordem:3});
    menu.push({id:11,apelido:"conteudo.sobre",link:Api.ADM_BASE+"/conteudo/sobre",icon:null,titulo:"Quem Somos",title:"Quem Somos",item_pai:8,ordem:1});
    menu.push({id:12,apelido:"modulos.logo",link:Api.ADM_BASE+"/modulos/logo",icon:null,titulo:"Logo",title:"Logo",item_pai:10,ordem:1});
    menu.push({id:13,apelido:"modulos.newsletter",link:Api.ADM_BASE+"/modulos/newsletter",icon:null,titulo:"Newsletter",title:"Newsletter",item_pai:10,ordem:2});
    menu.push({id:14,apelido:"modulos.rede_social",link:Api.ADM_BASE+"/modulos/redes-sociais",icon:null,titulo:"Redes Sociais",title:"Redes Sociais",item_pai:10,ordem:3});
    menu.push({id:15,apelido:"modulos.contato",link:Api.ADM_BASE+"/modulos/contato",icon:null,titulo:"Contato",title:"Contato",item_pai:10,ordem:4});
    menu.push({id:16,apelido:"modulos.endereco",link:Api.ADM_BASE+"/modulos/endereco",icon:null,titulo:"Endereço",title:"Endereço",item_pai:10,ordem:5});
    menu.push({id:17,apelido:"modulos.banner_slider",link:Api.ADM_BASE+"/modulos/banner-slider",icon:null,titulo:"Banner Slider",title:"Banner Slider",item_pai:10,ordem:6});
    menu.push({id:18,apelido:"conteudo.contato",link:Api.ADM_BASE+"/conteudo/contato",icon:null,titulo:"Contato",title:"Contato",item_pai:8,ordem:2});
    menu.push({id:22,apelido:"modulos.produto",link:Api.ADM_BASE+"/modulos/produto",icon:null,titulo:"Serviços",title:"Serviços",item_pai:10,ordem:7});
    menu.push({id:33,apelido:"modulos.whatsapp",link:Api.ADM_BASE+"/modulos/whatsapp",icon:null,titulo:"Whatsapp",title:"Whatsapp",item_pai:10,ordem:9});
    menu.push({id:42,apelido:"modulos.sobre",link:Api.ADM_BASE+"/modulos/sobre",icon:null,titulo:"Sobre",title:"Sobre",item_pai:10,ordem:13});
    menu.push({id:43,apelido:"loja",link:Api.ADM_BASE+"/loja",icon:null,titulo:"Loja",title:"Loja",item_pai:0,ordem:5});
    menu.push({id:46,apelido:"loja.configuracao",link:Api.ADM_BASE+"/loja/configuracao",icon:null,titulo:"Configuração",title:"Configuração",item_pai:43,ordem:0});
    menu.push({id:47,apelido:"loja.pagamento",link:Api.ADM_BASE+"/loja/pagamento",icon:null,titulo:"Pagamento",title:"Pagamento",item_pai:43,ordem:1});
    menu.push({id:48,apelido:"loja.pagamento.meios_de_pagamento",link:Api.ADM_BASE+"/loja/pagamento/meios-de-pagamento",icon:null,titulo:"Meios de Pagamento",title:"Meios de Pagamento",item_pai:47,ordem:1});
    menu.push({id:125,apelido:"loja.pagamento.chaves_de_acesso",link:Api.ADM_BASE+"/loja/pagamento/chaves-de-acesso",icon:null,titulo:"Chaves de Acesso",title:"Chaves de Acesso",item_pai:47,ordem:2});
    
    menu.push({id:49,apelido:"loja.pedido",link:Api.ADM_BASE+"/loja/pedido",icon:null,titulo:"Pedidos",title:"Pedidos",item_pai:43,ordem:1});
    menu.push({id:50,apelido:"loja.pedido.carrinho",link:Api.ADM_BASE+"/loja/pedido/carrinho",icon:null,titulo:"Carrinho de Compras",title:"Carrinho de Compras",item_pai:49,ordem:1});
    menu.push({id:86,apelido:"loja.pedido.pagafacil",link:Api.ADM_BASE+"/loja/pedido/pagafacil",icon:null,titulo:"Link de Pagamento",title:"Link de Pagamento",item_pai:49,ordem:2});
    
    menu.push({id:51,apelido:"conteudo.servicos",link:Api.ADM_BASE+"/conteudo/servicos",icon:null,titulo:"Serviços",title:"Serviços",item_pai:8,ordem:4});
    menu.push({id:52,apelido:"modulos.produto.produtos_em_destaques",link:Api.ADM_BASE+"/modulos/produto/destaque-1",icon:null,titulo:"Serviços em Destaque (Seção 1)",title:"Serviços em Destaque (Seção 1)",item_pai:22,ordem:1});
    menu.push({id:58,apelido:"modulos.destinos",link:Api.ADM_BASE+"/modulos/destinos",icon:null,titulo:"Destinos",title:"Destinos",item_pai:10,ordem:7});
    menu.push({id:59,apelido:"modulos.destinos.destinos",link:Api.ADM_BASE+"/modulos/destinos/destinos",icon:null,titulo:"Destinos",title:"Destinos",item_pai:58,ordem:1});
    menu.push({id:60,apelido:"conteudo.politicas_de_privacidade",link:Api.ADM_BASE+"/conteudo/politicas-de-privacidade",icon:null,titulo:"Políticas de Privacidade",title:"Políticas de Privacidade",item_pai:8,ordem:4});
    menu.push({id:61,apelido:"conteudo.politicas_de_cancelamento",link:Api.ADM_BASE+"/conteudo/politicas-de-cancelamento",icon:null,titulo:"Políticas de Cancelamento",title:"Políticas de Cancelamento",item_pai:8,ordem:5});
    menu.push({id:126,apelido:"conteudo.politicas_de_cookie",link:Api.ADM_BASE+"/conteudo/politicas-de-cookies",icon:null,titulo:"Políticas de Cookies",title:"Políticas de Cookies",item_pai:8,ordem:6});
         
    menu.push({id:62,apelido:"loja.dashboard",link:Api.ADM_BASE+"/loja/dashboard",icon:null,titulo:"Dashboard",title:"Dashboard",item_pai:43,ordem:4});
    menu.push({id:63,apelido:"loja.dashboard.venda",link:Api.ADM_BASE+"/loja/dashboard/venda",icon:null,titulo:"Vendas",title:"Vendas",item_pai:62,ordem:1});
    menu.push({id:71,apelido:"modulos.horarios",link:Api.ADM_BASE+"/modulos/horarios",icon:null,titulo:"Horários",title:"Horários",item_pai:10,ordem:9});
    menu.push({id:75,apelido:"modulos.fale_conosco",link:Api.ADM_BASE+"/modulos/fale-conosco",icon:null,titulo:"Fale Conosco",title:"Fale Conosco",item_pai:10,ordem:10});
    menu.push({id:79,apelido:"modulos.produto.produto_destaque_2",link:Api.ADM_BASE+"/modulos/produto/destaque-2",icon:null,titulo:"Serviços em Destaque (Seção 2)",title:"Serviços em Desatque (Seção 2)",item_pai:22,ordem:2});
    menu.push({id:90,apelido:"modulos.dicas",link:Api.ADM_BASE+"/modulos/dicas",icon:null,titulo:"Dicas",title:"Dicas",item_pai:10,ordem:1});
    menu.push({id:93,apelido:"conteudo.destinos",link:Api.ADM_BASE+"/conteudo/destinos",icon:null,titulo:"Destinos",title:"Destinos",item_pai:8,ordem:5}); 
    menu.push({id:94,apelido:"conteudo.carrinho",link:Api.ADM_BASE+"/conteudo/carrinho",icon:null,titulo:"Carrinho",title:"Carrinho",item_pai:8,ordem:5}); 
    menu.push({id:95,apelido:"modulos.pagamento",link:Api.ADM_BASE+"/modulos/pagamento",icon:null,titulo:"Pagamento",title:"Pagamento",item_pai:10,ordem:10});  
    menu.push({id:96,apelido:"modulos.pagamento.pagamento",link:Api.ADM_BASE+"/modulos/pagamento/pagamento",icon:null,titulo:"Pagamento",title:"Pagamento",item_pai:95,ordem:10});  
    //menu.push({id:100,apelido:"conteudo.politicas_de_seguranca",link:Api.ADM_BASE+"/conteudo/politicas-de-seguranca",icon:null,titulo:"Políticas de Segurança",title:"Políticas de Segurança",item_pai:8,ordem:6});
    menu.push({id:101,apelido:"loja.usuario",link:Api.ADM_BASE+"/loja/usuario",icon:null,titulo:"Usuário",title:"Usuário",item_pai:43,ordem:5});
    menu.push({id:102,apelido:"loja.usuario.usuario",link:Api.ADM_BASE+"/loja/usuario/usuarios-da-loja",icon:null,titulo:"Usuários da Loja",title:"Usuários da Loja",item_pai:101,ordem:1});
    menu.push({id:107,apelido:"loja.dashboard.operacional",link:Api.ADM_BASE+"/loja/dashboard/operacional",icon:null,titulo:"Operacional",title:"Operacional",item_pai:62,ordem:2});
    menu.push({id:108,apelido:"loja.notificacao",link:Api.ADM_BASE+"/loja/notificacao",icon:null,titulo:"Notificação",title:"Notificação",item_pai:43,ordem:0});
    menu.push({id:109,apelido:"loja.notificacao.email",link:Api.ADM_BASE+"/loja/notificacao/email",icon:null,titulo:"E-mail",title:"E-mail",item_pai:108,ordem:1});
    menu.push({id:110,apelido:"loja.notificacao.empresa",link:Api.ADM_BASE+"/loja/notificacao/empresa",icon:null,titulo:"Empresas",title:"Empresas",item_pai:108,ordem:2});
    menu.push({id:111,apelido:"loja.notificacao.contato",link:Api.ADM_BASE+"/loja/notificacao/contato",icon:null,titulo:"Contatos",title:"Contatos",item_pai:108,ordem:3});

    menu.push({id:112,apelido:"relatorio",link:Api.ADM_BASE+"/relatorio",icon:null,titulo:"Relatório",title:"Relatório",item_pai:0,ordem:5});
    menu.push({id:113,apelido:"relatorio.gerencial",link:Api.ADM_BASE+"/relatorio/gerencial",icon:null,titulo:"Gerencial",title:"Gerencial",item_pai:112,ordem:10});
    menu.push({id:114,apelido:"relatorio.gerencial.produto_venda",link:Api.ADM_BASE+"/relatorio/gerencial/venda-por-produto",icon:null,titulo:"Venda por Produto",title:"Venda por Produto",item_pai:113,ordem:10});
    menu.push({id:124,apelido:"relatorio.google_analytics",link:Api.ADM_BASE+"/relatorio/google-analytics",icon:null,titulo:"Google Anaytics",title:"Google Analytics",item_pai:112,ordem:10});
    
    menu.push({id:115,apelido:"modulos.pagamento.paga_facil",link:Api.ADM_BASE+"/modulos/pagamento/paga-facil",icon:null,titulo:"Paga Fácil",title:"Paga Fácil",item_pai:95,ordem:11});  
    menu.push({id:116,apelido:"modulos.parceiro",link:Api.ADM_BASE+"/modulos/parceiro",icon:null,titulo:"Seja um Parceiro",title:"Seja um Parceiro",item_pai:10,ordem:12});  
    menu.push({id:117,apelido:"modulos.mapa_regiao",link:Api.ADM_BASE+"/modulos/mapa-regiao",icon:null,titulo:"Mapa Região",title:"Mapa Região",item_pai:10,ordem:13});  
    
    menu.push({id:118,apelido:"conteudo.minha_conta",link:Api.ADM_BASE+"/conteudo/minha-conta",icon:null,titulo:"Minha Conta",title:"Minha Conta",item_pai:8,ordem:1});
    menu.push({id:119,apelido:"conteudo.minha_conta.perfil",link:Api.ADM_BASE+"/conteudo/minha-conta/perfil",icon:null,titulo:"Perfil",title:"Perfil",item_pai:118,ordem:1});
    menu.push({id:120,apelido:"conteudo.minha_conta.pedidos",link:Api.ADM_BASE+"/conteudo/minha-conta/pedidos",icon:null,titulo:"Pedidos",title:"Pedidos",item_pai:118,ordem:2});
    menu.push({id:121,apelido:"conteudo.minha_conta.favoritos",link:Api.ADM_BASE+"/conteudo/minha-conta/favoritos",icon:null,titulo:"Favoritos",title:"Favoritos",item_pai:118,ordem:3});
    
    menu.push({id:127,apelido:"conteudo.duvida_frequente",link:Api.ADM_BASE+"/conteudo/duvida-frequente",icon:null,titulo:"Dúvida Frequentes",title:"Dúvida Frequentes",item_pai:8,ordem:3});
    
    menu.push({id:122,apelido:"operacional",link:Api.ADM_BASE+"/operacional",icon:null,titulo:"Operacional",title:"Operacional",item_pai:0,ordem:4});
    menu.push({id:123,apelido:"operacional.contato",link:Api.ADM_BASE+"/operacional/contato",icon:null,titulo:"Contato",title:"Contato",item_pai:122,ordem:1});
    */
  

    return menu.sort(this.order);       
  
  }
  /**
   * 
   * Ordenação do Menu
   * 
   */
  order(a,b){

    var ordem_a = typeof(a.ordem) != "undefined" ? a.ordem : 0;
    var ordem_b = typeof(b.ordem) != "undefined" ? b.ordem : 0;

    if (ordem_a > ordem_b) {
      return 1;
    }
    if (ordem_a < ordem_b) {
      return -1;
    }
    return 0;

  }



}
