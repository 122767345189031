import { Component, OnInit, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'topo-layout',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.scss'] 
})
export class TopoComponent implements OnInit {

  @Input("drawer") drawer:MatDrawer; 
  public icon = "arrow_back";
  private innerWidth:any;

  constructor() { } 

  /**
   * 
   * Habilita e desabilita o MENU
   * 
   * 
   */
  toggle(){

    this.drawer.toggle();
  
  }
  onData(){

    this.drawer.closedStart.subscribe(data => {
      this.icon = "menu";
    });
    this.drawer.openedStart.subscribe(data => {
      this.icon = "close";
    });

  }
  /**
   * 
   * Verifica de é MOBILE
   * 
   */
  verifyMobile(){

    if(this.innerWidth <= 959){

      this.drawer.close();
      this.icon = "menu"; 

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.innerWidth = window.innerWidth;
    this.onData();
    this.verifyMobile();
  }

}
