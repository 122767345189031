import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaskMoneyDirective } from 'src/app/directive/mask-money.directive';
import { MaskDirective } from 'src/app/directive/mask.directive';
import { CompileHtmlDirective } from 'src/app/directive/compile-html.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialModule } from '../partial/partial.module';
import { UppercaseDirective } from 'src/app/directive/uppercase.directive';
import { IntegerDirective } from '../../directive/integer.directive';

@NgModule({
  declarations: [
    MaskDirective,
    CompileHtmlDirective,
    MaskMoneyDirective,
    UppercaseDirective,
    IntegerDirective
  ],
  exports: [
    MaskDirective,
    CompileHtmlDirective,
    MaskMoneyDirective,
    UppercaseDirective,
    IntegerDirective  
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartialModule
  ]
})
export class DirectiveModule { }
