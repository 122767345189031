import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * 
   * 
   * Set user
   * 
   */
   setDates(user){

    if(typeof(localStorage) != "undefined"){

      localStorage.setItem("dates",JSON.stringify(user))

    }

  }
  /**
   * 
   * 
   * Get user
   * 
   */
   getDates(){

    if(typeof(localStorage) != "undefined"){

      let user = localStorage.getItem("dates");

      if(user != null){

        return JSON.parse(user);

      } 

    }

    return null;

  }
  /**
   * 
   * 
   * Set user
   * 
   */
  setUser(user){

    if(typeof(localStorage) != "undefined"){

      localStorage.setItem("user",JSON.stringify(user))

    }

  }
  /**
   * 
   * 
   * Get user
   * 
   */
  getUser(){

    if(typeof(localStorage) != "undefined"){

      let user = localStorage.getItem("user");

      if(user != null){

        return JSON.parse(user);

      } 

    }

    return null;

  }
  /**
   * 
   * 
   * Set user
   * 
   */
   setProducts(products){

    if(typeof(localStorage) != "undefined"){

      localStorage.setItem("products",JSON.stringify(products))

    }

  }
  /**
   * 
   * 
   * Get user
   * 
   */
  getProducts(){

    if(typeof(localStorage) != "undefined"){

      let products = localStorage.getItem("products");

      if(products != null){

        return JSON.parse(products);

      } 

    }

    return null;

  }
  /***
   * 
   * Delete
   * 
   */
  removeProducts(){

    localStorage.removeItem("products");

  }
  /**
   * 
   * Set Usuário
   * 
   */
  setPayment(data){
    sessionStorage.setItem("payment_data",JSON.stringify(data));
  }
  /**
   * 
   * Get Data File
   * 
   */
  getPayment(){

    let data = sessionStorage.getItem("payment_data");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }
    

  }
  /**
   * 
   * Set Usuário
   * 
   */
   setPaymentLink(data){
    sessionStorage.setItem("payment_link_data",JSON.stringify(data));
  }
  /**
   * 
   * Get Data File
   * 
   */
  getPaymentLink(){

    let data = sessionStorage.getItem("payment_link_data");

    if(data == null){
      return null;
    }else{
      return JSON.parse(data);
    }
    

  }

}
