import { Directive, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationIconComponent } from '../../../components/notification/notification-icon/notification-icon.component';
import { PartialModule } from '../../partial/partial.module';
import { MaterialModule } from '../../material/material.module';
import { DirectiveModule } from '../../directive/directive.module';
import { PipeModule } from '../../pipe/pipe.module';


@NgModule({
  declarations: [NotificationIconComponent],
  exports: [NotificationIconComponent],
  imports: [
    CommonModule,
    PartialModule,
    MaterialModule,
    DirectiveModule,
    PipeModule
  ]
})
export class NotificationPartialModule { }
