import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DmGridConfirmDeleteComponent } from '../components/grid/delete/dm-grid-confirm-delete/dm-grid-confirm-delete.component';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(
    private dialog: MatDialog   
  ){ 

  }
  /**
   * 
   * Modal - Confirm Delete
   * 
   */
  confirmDelete(data,width="400px",height="auto"){

    return this.dialog.open(DmGridConfirmDeleteComponent,{ 
      width: width,
      height: height,
      data: data
    });

  }
  
}
