import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class RouteAdminGuard implements CanActivate {

  constructor(
    private router: Router, 
    private storage: StorageService,
    private app: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      let user = this.storage.getUser();
 
      if(user !=  null){
        if(user.acessoAdmin === false || user.acessoAdmin === "false"){
            this.router.navigateByUrl("/"); 
        }
      }else{
        this.router.navigateByUrl("/"); 
      }

      return true;

  }
}