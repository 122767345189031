<div class="dm-modal">
    <div class="modal-title">
      <span class="text">Atenção</span>
    </div>  
    <div class="modal-body">
      <article class="message" [innerHtml]="data.message"></article>    
    </div>
    <div class="modal-footer">
      <div class="center">
        <button type="button" class="btn btn-one btn-icon" (click)="close()">
            <i class="material-icons">check_circle</i>
            <span>OK</span>
        </button> 
      </div>
    </div>   
</div>
  