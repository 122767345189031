import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmInputComponent } from 'src/app/components/form/input/dm-input/dm-input.component';
import { FormTitleComponent } from 'src/app/components/form/form-title/form-title.component';
import { FormToolbarComponent } from 'src/app/components/form/form-toolbar/form-toolbar.component';
import { PartialModule } from '../partial/partial.module';
import { DirectiveModule } from '../directive/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';

@NgModule({
  declarations: [
    DmInputComponent,
    FormTitleComponent,
    FormToolbarComponent
  ],
  exports: [
    DmInputComponent,
    FormTitleComponent,
    FormToolbarComponent
  ],
  imports: [
    MaterialModule,
    CommonModule, 
    PartialModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule  
  ]
})
export class FormModule { }
